<template lang="html">
  <transition name="dialog-t">
    <div v-if="isPrompt ? active : fActive" ref="con" :class="[`vs-dialog-${color}`]" class="shipblu-prompt vs-component con-vs-dialog">
      <div class="vs-dialog-dark" @click="$route.name === 'growth-hacker-promo-codes' || $route.name === 'growth-hacker-exception-discount'? '' : handleClose($event,true)"/>
      <div ref="dialogx" class="vs-dialog modal">
        <!-- //header -->
        <div class="p-4">
          <div class="flex justify-end">
            <feather-icon class="text-icon cursor-pointer" v-if="type=='alert'" :icon="closeIcon" svgClasses="h-5 w-5" :icon-pack="iconPack" @click="handleClose($event)"/>
          </div>
          <div class="text-center">
            <h3 class="mt-2 font-medium text-primary text-xl justify-center flex">
              <feather-icon :icon="titleIcon" svgClasses="w-6 h-5 mr-1"/>
                {{ title }} 
            </h3>
          </div>
        </div>     
        <!-- // slots  -->
        <div class="prompt-font px-5 pb-7 vs-dialog-text">
          <slot/>
          {{ text }}
        </div>
        <!-- footer buttons -->
        <div v-if="buttonsHidden?false:isPrompt||type=='confirm'" class="px-7 pb-8 w-full flex gap-4">
          <vs-button :text-color="'rgba(0,0,0,.5)'" :type="buttonCancel" class="vs-dialog-cancel-button w-1/2" @click="cancelClose">{{ cancelText }}</vs-button>
          <vs-button :disabled="isValid=='none'?false:!isValid" :color="color" :type="buttonAccept" class="w-1/2 vs-dialog-accept-button" @click="acceptDialog">{{ acceptText }}</vs-button>
        </div>
        <footer v-if="type=='alert'&&!isPrompt" >
          <vs-button :color="color" :type="buttonAccept" class="vs-dialog-accept-button" @click="acceptDialog">{{ acceptText }}</vs-button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

/* eslint-disable */
export default {
  name:'VsPrompt',
  props:{
    color:{
      default:'primary',
      type:String
    },
    active:{
      default:false,
      type: Boolean
    },
    buttonAccept:{
      default:'filled',
      type:String,
    },
    buttonCancel:{
      default:'flat',
      type:String,
    },
    isValid:{
      default:'none',
      type:[Boolean,String]
    },
    buttonsHidden:{
      default:false,
      type:Boolean
    },
    acceptText:{
      default:'Accept',
      type:String
    },
    cancelText:{
      default:'Cancel',
      type:String
    },
    iconPack:{
      default:'feather',
      type:String
    },
    closeIcon:{
      default:'XSquareIcon',
      type:String
    },
    text:{
      default: null,
      type: String
    },
    title:{
      default: 'Dialog',
      type: String
    },
    titleIcon:{
      default: '',
      type: String
    },
    type:{
      default: 'alert',
      type: String
    },
    parent:{
      default: null,
    }
  },
  data:()=>({
    settings: {
      maxScrollbarLength: 60,
      wheelSpeed: 0.30
    },
    isPrompt:true,
    fActive: false,
    parameters: null,
  }),
  watch:{
    active(val) {
      this.$nextTick(() => {
        if (this.active) {
          this.insertBody()
        }
      })
    },
    fActive() {
      this.$nextTick(() => {
        if (this.fActive) {
          this.insertBody()
        }
      })
    }
  },
  computed: {
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  mounted () {
    if (this.active && this.isPrompt) {
      this.insertBody()
    }
    this.fActive = this.active
  },
  beforeDestroy() {
    // close the left open prompt
    let elx = this.$refs.con
    let parentx = this.parent ? this.parent : document.body
    if (elx) {
      parentx.removeChild(elx)
    }
  },
  methods:{
    acceptDialog () {
      this.$emit('acceptFunction')
    },
    rebound(){
      this.$refs.dialogx.classList.add('locked')
      setTimeout( () => {
        this.$refs.dialogx.classList.remove('locked')
      }, 200);
    },
    handleClose(event,con){
      if(con){
        if(event.target.className.indexOf('vs-dialog-dark')!=-1 && this.type == 'alert'){
          this.fActive = false
          this.$emit('update:active',false)
        } else if (event.target.className.indexOf('vs-dialog-dark')!=-1) {
          this.rebound()
        }
      } else {
          this.$emit('update:active',false)
          this.fActive = false
      }
      this.$emit('close')
    },
    cancelClose(){
      this.fActive = false
      this.$emit('update:active',false)
      this.$emit('cancel')
      this.cancel?this.cancel(this.parameters):null

    },
    insertBody(){
      let elx = this.$refs.con
      let parentx = this.parent ? this.parent : document.body
      parentx.insertBefore(elx, parentx.firstChild)
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

<style lang="scss">
.text-icon {
  color: #9DADC2;
}
.vs-dialog-dark {
  background: rgba(10, 50, 102, 0.24);
  backdrop-filter: blur(2px);
}
.vs-dialog {
  background: #FFFFFF;
  box-shadow: 0px 2px 16px rgba(157, 173, 194, 0.38);
  border-radius: 6px;
  max-height: 90%;
  overflow-x: hidden;
  overflow-y: auto;
}
.vs-dialog-accept-button {
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
}
.vs-dialog-cancel-button {
  background: #F1F3F6 !important;
  border-radius: 4px;
  color: #6C84A3;
  font-weight: 500;
  font-size: 16px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px #c1c1c195; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #62626265; 
  border-radius: 6px;
}
.btn {
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  line-height: 18px;
} 
.disable-btn {
  background: #F1F3F6;
  color: #6C84A3;
}
.active-btn {
  color: #fff;
  font-weight: 500;
  background: #1C5BFE;
  box-shadow: 0px 2px 8px rgba(10, 50, 102, 0.32);
}
</style>